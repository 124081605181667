<template>
	<div class="page home_page">
		<div class="bannerbox" @click="playvideosore()">
			<el-carousel indicator-position="none">
			    <el-carousel-item v-for="(item,index) in bannerList" :key="index">
			       <div class="imgbox">
					   <!-- :poster="item.imageUrl" -->
					    <video v-show="item.videoUrl" :src="item.videoUrl" 
						class="video img100"
						id="video"
						ref="videoPlayer"
						style="object-fit: cover;"
						:loop = "true"
						:autoplay="true"
						controls
						muted
						playsinline x5-playsinline webkit-playsinline x5-video-player-fullscreen></video>
					    <img v-if="!item.videoUrl" class="img100" :src="item.imageUrl"/>
						<div class="titlebox">
						   <div class="text1 fontsize36" v-if="item.title">{{item.title}}</div>
						   <div class="text2 fontsize16" v-if="item.subtitle">{{item.subtitle}}</div>
						  <div v-if="item.redirectUrl&&item.redirectUrl.trim()" class="navicon" @click="handleSearche(item.redirectUrl)"><img class="img100" src="../assets/static/navto.png"/></div>
					    </div>
					</div>
			    </el-carousel-item>
			</el-carousel>
		</div>
		<div class="home_contentbox">
			<div class="contentbox w1200" v-if="typeList.length>0">
				<div class="leftbox">
					<div class="typebox">
						<div class="text fontsize32">{{typeList[0].title}}</div>
						<div class="morebox" @click="navigato('/information',{uid:typeList[0].id})">
							<div class="text1 fontsize20">更多</div>
							<img class="icon" src="../assets/static/Frame427321188.png"/>
						</div>
					</div>
					<div class="conbox" v-if="typeList1.length>0">
						<el-carousel arrow="never" indicator-position="none" :interval="1800">
						    <el-carousel-item v-for="(item,index) in typeList1" :key="index">
								<div class="box1" @click="handledetail(item.id)">
									<div class="imgbox">
									   <img class="img100" :src="item.imageUrl"/>
									   <!-- <div class="timer fontsize20">{{item.timer}}</div> -->
									</div>
									<div class="textbox">
										<div class="leftdiv">
											<div class="text1 clamp fontsize20">{{item.title}}</div>
											<div class="text2 clamp fontsize14">{{item.body}}</div>
										</div>
										<div class="rightdiv">
											<img class="img100" src="../assets/static/Frame427321188.png"/>
										</div>
									</div>
								</div>
						    </el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<div class="rightbox" v-if="typeList.length>1">
					<div class="typebox">
						<div class="text fontsize24">{{typeList[1].title}}</div>
						<div class="morebox" @click="navigato('/information',{uid:typeList[1].id})">
							<div class="text1 fontsize20">更多资讯</div>
							<img class="icon" src="../assets/static/Frame427321188.png"/>
						</div>
					</div>
					<div class="conbox" v-if="typeList2.length>0">
						<el-carousel arrow="never" indicator-position="none" :interval="1800">
						    <el-carousel-item v-for="(item,index) in typeList2" :key="index">
								<div class="box1" @click="handledetail(item.id)">
									<div class="imgbox">
									   <img class="img100" :src="item.imageUrl"/>
									   <!-- <div class="timer fontsize20">{{item.timer}}</div> -->
									</div>
									<div class="textbox">
										<div class="leftdiv">
											<div class="text1 clamp fontsize20">{{item.title}}</div>
											<div class="text2 clamp3 fontsize14">{{item.body}}</div>
										</div>
									</div>
								</div>
						    </el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>
		</div>
		<div class="home_datalist_box">
			<div class="datalist w1240">
				<div class="box1" v-for="(item,index) in bannerList2" :key="index" @click="handleSearche(item.redirectUrl)">
					<div class="imgbox">
					   <img class="img100 hoverbig" :src="item.imageUrl"/>
					</div>
					<div class="textbox">
						<div class="text1 clamp2 fontsize20">{{item.title}}</div>
						<div class="text2 clamp2 fontsize14">{{item.subtitle}}</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="block58"></div>
	</div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
export default {
	data() {
		return {
			bannerList: [],
			bannerList2: [],
			typeList:[],//类型
			typeList1:[],//类型1的内容容
			typeList2:[],//类型2的内容
		};
	},
	mounted: function() {
		//初始化
		this.initPage()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole']),
	},
	activated() {
	    console.log('Component A activated');
		var _this = this
		this.$nextTick(() => {
			if (_this.bannerList.length>0&&_this.$refs.videoPlayer&&_this.$refs.videoPlayer[0].paused) {
				// _this.$refs.videoPlayer.paused
				console.log(_this.$refs.videoPlayer)
				_this.$refs.videoPlayer[0].play()
			}
		});
	},
	methods: {
		...mapMutations(["SET_USERINFO",'SET_ISLOGIN']),
		//初始化
		async initPage(){
			//轮播图和广告
			await this.getwheelPage(1)
			//获取类型
			this.pageArticleType()
			await this.getwheelPage(2)
		},
		//播放视频音频
		playvideosore(){
			var _this = this
			if (_this.bannerList.length>0&&_this.$refs.videoPlayer&&_this.$refs.videoPlayer[0].muted) {
				//console.log(_this.$refs.videoPlayer)
				_this.$refs.videoPlayer[0].muted = false
			}
		},
		//跳转
		navigato(url,query) {
			this.$util.routerPath(url,query)
		},
		//文章
		handledetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
		},
		//获取类型
		pageArticleType() {
			var _this = this
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 2
			params["infoTags"] = "article"
			this.$http.post('app/articleType/pageArticleType', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.typeList = res.data.records
					_this.getpageArticle()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		async getpageArticle(){
			if(this.typeList.length>0){
				var list1 = await this.pageArticle(this.typeList[0].id)
				if(list1){
					this.typeList1 =list1
				}
				if(this.typeList.length>1){
					var list2 = await this.pageArticle(this.typeList[1].id)
					if(list2){
						this.typeList2 =list2
					}
				}
			}
		},
		//获取内容
		pageArticle(typeId) {
			var _this = this
			return new Promise((resolve, reject) => {
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 5
				params["typeId"] = typeId
				params["infoTags"] = "article"
				this.$http.post('app/article/pageArticle', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						if(records.length>0){
							records.forEach((item,index)=>{
								if(item.createDate){
									item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
								}
								if(item.contents){
									item["body"] = _this.$util.filtersText(item.contents,218)
								}
								item["imageUrl"] = ""
								if(item.imgUrlList&&item.imgUrlList.length>0){
									item["imageUrl"] = item.imgUrlList[0]
								}
								// if(item.releaseTime){
								// 	item["releaseTime"] = _this.$util.formatdate(_this.$util.getDateTime(item.releaseTime))
								// }
							})
						}
						resolve(records)
					} else {
						reject(false)
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			})
		},
		//获取轮播图
		getwheelPage(type) {
			var _this = this
			return new Promise((resolve, reject) => {
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 18
				params["type"] = type
				this.$http.post('app/wheel-advert/getPage', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						// imageUrl:require("../assets/static/banner1.png")
						if(records.length>0){
							if(type==1){
								_this.bannerList = records
							}else if(type==2){
								_this.bannerList2 = records
							}else{}
						}
						resolve(true)
					} else {
						reject(false)
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			})
		},
		//跳转
		handleSearche(url){
			if(url&&url!='no'&&url!='No'&&url!='NO'){
				if(url.indexOf("http://")>-1 || url.indexOf("https://")>-1){
					this.$util.windowOpen(url)
				}else{
					const newUrl = url.split('?')[0]
					const params = this.parseString(url)
					this.$router.push({
						path: "/"+newUrl,
						query:params
					});
				}
			}else{
				console.log("无跳转路径")
				//this.$message.info("此模块暂未开放")
			}
		},
		//处理参数
		parseString(url){
		    const obj = {};
		    if (url&&url.split('?').length>1) {
		      const params = url.split('?')[1].split('&');
		      params.map(item => obj[item.split("=")[0]] = item.split("=")[1]);
		    }
		    console.log(obj)
			return obj
		},
		
	}
};
</script>
<style lang="scss" scoped>
	.hoverbig:hover{
	    transform: scale(1.05);
	    transition: ease all 0.3s;
	}
</style>
